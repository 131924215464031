/* eslint eqeqeq: 0 */
import {
  AppBar,
  Box,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { BsCloudSun } from "react-icons/bs";
import { BsCloudMoon } from "react-icons/bs";
import { CiSettings } from "react-icons/ci";
const EdemandSetting = ({
  changeLight,
  changeDark,
  setOpenSetting,
  view,
  setView,
}) => {
  const [lang, setlang] = React.useState("en");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const storedLang = localStorage.getItem("i18nextLng");
    if (storedLang) {
      setlang(storedLang == "en" || storedLang == "en-US" ? "en" : storedLang);
    }
  }, []);

  const changeLanguage = (lng) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
    setlang(i18n);
  };

  const handleCloseSetting = () => {
    setOpenSetting(false);
  };

  const handleChangeLanguage = (event) => {
    let selectedLang = event.target.value;
    if (!selectedLang) {
      selectedLang = "en"; // Set default language if no value is selected
      setlang("en");
    } else {
      setlang(selectedLang);
      localStorage.setItem("i18nextLng", selectedLang);
      handleCloseSetting();
      // window.location.reload();
    }
  };

  const darkMode = localStorage.getItem("darkMode");
  const pageDirection = localStorage.getItem("pageDirection");
  // eslint-disable-next-line no-unused-vars
  const [direction, setDirection] = useState(pageDirection);

  const handleChangeTheme = (event, nextView) => {
    setView(nextView);
    localStorage.setItem("SelectedTheme", nextView); // Store the selected view
    window.location.reload();
  };
  // eslint-disable-next-line no-unused-vars
  const handlePageDirection = (e) => {
    setDirection(e);
    document.documentElement.setAttribute("dir", e);
    localStorage.setItem("pageDirection", e); // Store the selected view
    // window.location.reload();
  };
  const theme = useTheme();
  return (
    <div>
      <Box width={"100%"}>
        <Box width={"100%"}>
          {/* Heading  */}
          <AppBar
            position="static"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Toolbar sx={{ display: "flex", justifyContent: "space-between",alignItems: 'center',width: '100%'}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                gap={2}
              >
                <IconButton
                  size="medium"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{
                    border: 1,
                    borderRadius: "12px",
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  <SettingsOutlinedIcon fontSize="medium" color="primary" />
                </IconButton>

                <Typography
                  variant="body1"
                  component="div"
                  className="edemand-flexgrow"
                >
                  {process.env.REACT_APP_NAME + " " + t("settings")}
                </Typography>
              </Box>
              <Box>

              <IconButton
                onClick={handleCloseSetting}
                sx={{
                  border: 1,
                  backgroundColor: "white",
                  padding: 0,
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
                size="small"
              >
                <CloseIcon color="primary" fontSize="small" />
              </IconButton>
              </Box>

            </Toolbar>
          </AppBar>
          {/* Toggle Button for Modes  */}

          <Box display={"flex"} mx={3} my={5} flexDirection={"column"}>
            <FormLabel sx={{ fontWeight: "bold" }}> {t("theme")} </FormLabel>
            <Box mt={1}>
              <ToggleButtonGroup
                value={view}
                exclusive
                onChange={handleChangeTheme}
                sx={{ width: "100%", display: "flex", gap: "2" }}
              >
                <Box
                  display={"flex"}
                  width={"100%"}
                  gap={4}
                  flexDirection={{ xs: "column", md: "row" }}
                  justifyContent={"space-between"}
                  alignContent={"center"}
                >
                  <ToggleButton
                    onClick={changeLight}
                    value="list"
                    aria-label="list"
                    sx={{
                      backgroundColor: "#2560FC1A",
                      width: "100%",
                      "&:hover": {
                        backgroundColor: "#2560FC1A",
                      },
                    }}
                  >
                    <Box
                      display={"flex"}
                      width={"100%"}
                      justifyContent={"space-between"}
                      alignContent={"center"}
                      gap={1}
                    >
                      <Box display={"flex"} alignContent={"center"} gap={2}>
                        <BsCloudSun className="theme-btn" />
                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography variant="body1">{t("light")}</Typography>
                          <Typography variant="caption">{t("mode")}</Typography>
                        </Box>
                      </Box>
                      <Box>
                        <RadioGroup
                          defaultValue="medium"
                          name="radio-buttons-group"
                          value={darkMode === "true" ? "dark" : "light"}
                        >
                          <Radio value="light" label="light" size="small" />
                        </RadioGroup>
                      </Box>
                    </Box>
                  </ToggleButton>

                  <ToggleButton
                    onClick={changeDark}
                    value="module"
                    aria-label="module"
                    sx={{
                      width: "100%",
                      backgroundColor: "#343F53",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#343F53",
                      },
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignContent={"center"}
                      justifyContent={"space-between"}
                      width={"100%"}
                      gap={1}
                    >
                      <Box display={"flex"} alignContent={"center"} gap={2}>
                        <BsCloudMoon className="theme-btn" />
                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography variant="body1">{t("dark")}</Typography>
                          <Typography variant="caption">{t("mode")}</Typography>
                        </Box>
                      </Box>
                      <Box>
                        <RadioGroup
                          defaultValue="medium"
                          name="radio-buttons-group"
                          value={darkMode === "true" ? "dark" : "light"}
                        >
                          <Radio value="dark" label="dark" size="small" />
                        </RadioGroup>
                      </Box>
                    </Box>
                  </ToggleButton>
                </Box>
              </ToggleButtonGroup>
            </Box>
          </Box>

          <Box display={"flex"} justifyContent={"center"} mx={3}>
            <FormControl sx={{ maxWidth: "100%", width: "100%" }}>
              <FormLabel sx={{ mb: 1 }}> {t("language")} </FormLabel>
              <Select
                id="language"
                value={lang}
                fullWidth
                input={<OutlinedInput fullWidth />}
                onChange={handleChangeLanguage}
                defaultValue={lang}
              >
                <MenuItem
                  value={"en"}
                  onClick={() => {
                    changeLanguage("en");
                  }}
                >
                  {t("english")}
                </MenuItem>
                <MenuItem value={"ar"} onClick={() => changeLanguage("ar")}>
                  {t("arabic")}
                </MenuItem>
                <MenuItem value={"sv"} onClick={() => changeLanguage("sv")}>
                  {t("swedish")}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default EdemandSetting;
{
  /* <Box display={"flex"} mx={3} my={5} flexDirection={"column"}>
                        <FormLabel sx={{ fontWeight: "bold" }}> {t("page_view")} </FormLabel>
                        <Box display={"flex"} gap={3} mt={1}>
                            <ListItem button onClick={e => handlePageDirection("LTR")}
                                sx={{
                                    border: 1,
                                    borderRadius: "8px",
                                    borderColor: "gray",
                                }}

                            >
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faAlignLeft} className="fa-3x w-100" />
                                </ListItemIcon>
                                <ListItemText primary={"LTR"} secondary={t("ltr")} />
                                <Radio
                                    checked={direction == "LTR" ? true : false}
                                    onChange={e => handlePageDirection("LTR")}
                                    value={"LTR"}
                                    edge="end"
                                />
                            </ListItem>
                            <ListItem button onClick={e => handlePageDirection("RTL")}
                                sx={{
                                    border: 1,
                                    borderRadius: "8px",
                                    borderColor: "gray",

                                }}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faAlignRight} className="fa-3x w-100" />
                                </ListItemIcon>
                                <ListItemText primary={"RTL"} secondary={t("rtl")} />
                                <Radio
                                    checked={direction == "RTL" ? true : false}
                                    onChange={e => handlePageDirection("RTL")}
                                    value={"RTL"}
                                    edge="end"
                                />
                            </ListItem>
                        </Box>
                    </Box> */
}
